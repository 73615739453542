//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiSearchtabs',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    current: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    onFilter(value) {
      this.$emit('search:filter', value)
    },
  },
}
