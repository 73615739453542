//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'default',
    },
    theme: {
      type: String,
      default: 'primary',
    },
  },
}
