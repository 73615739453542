//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'inline',
    },
    position: {
      type: String,
      default: 'bottom',
    },
  },
  computed: {
    dialogPosition() {
      return this.variant === 'site' ? null : this.position
    },
  },
  methods: {
    close() {
      if (this.id === 'share') {
        this.$store.commit('global/closeShare')
      } else {
        this.$store.commit('global/setDialog', {
          dialog: null,
          layer: false,
          props: null,
        })
      }
    },
  },
}
