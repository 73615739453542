//
//
//
//
//

export default {
  props: {
    variant: {
      type: String,
      default: null,
    },
  },
  computed: {
    classList() {
      return {
        'button-group': true,
        ['button-group--variant-' + this.variant]: this.variant,
      }
    },
  },
}
