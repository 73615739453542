//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getHearingJourney from '~/gql/getHearingJourney'

import proseText from '~/mixins/proseText'

export default {
  mixins: [proseText],
  props: {
    id: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      title: null,
      text: null,
      embeds: [],
    }
  },

  async fetch() {
    const { articles } = await this.$graphcms.request(getHearingJourney, {
      id: this.id,
      lang: [this.$i18n.locale],
      assetLocales: [this.$i18n.locale, this.$i18n.defaultLocale],
    })

    const article = articles[0]
    this.title = article.title
    this.text = article.content.html
    this.embeds = article.content.references
  },

  watch: {
    id(newId, oldId) {
      this.$fetch()
    },
  },

  methods: {
    hideDetails() {
      this.$emit('hideDetails')
    },
  },
}
