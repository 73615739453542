//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: [Object, String],
      default() {
        return null
      },
    },
    iconRight: {
      type: String,
      default: null,
    },
    iconLeft: {
      type: String,
      default: null,
    },
    iconSize: {
      type: [String, Number],
      default: 24,
    },
  },

  computed: {
    attributes() {
      let attributes = {}

      if (this.tag === 'button') {
        attributes = {
          type: 'button',
        }
      }
      if (this.tag === 'a') {
        attributes = {
          href: this.href,
        }
      }
      if (this.tag === 'nuxt-link') {
        attributes = {
          to: this.to,
        }
      }

      return attributes
    },

    tag() {
      let tag = 'button'
      if (this.href) {
        tag = 'a'
      }
      if (this.to) {
        tag = 'nuxt-link'
      }
      return tag
    },

    classList() {
      return {
        ['button--variant-' + this.variant]: this.variant,
        ['button--theme-' + this.theme]: this.theme,
        ['button--size-' + this.size]: this.size,
      }
    },

    leftIcon() {
      return this.iconLeft
    },

    rightIcon() {
      if (this.variant === 'link') {
        return this.iconRight ? this.iconRight : 'arrow-right'
      }
      return this.iconRight
    },
  },
}
