//
//
//
//
//
//

export default {
  name: 'Alert',
  props: {
    size: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    borders: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    wrapperClass() {
      const classList = []
      if (this.type) {
        classList.push(`alert--type-${this.type}`)
      }
      if (this.size) {
        classList.push(`alert--size-${this.size}`)
      }
      if (!this.borders) {
        classList.push('alert--no-border')
      }
      return classList.join(' ')
    },
  },
}
