//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    src: {
      type: String,
      default: '',
      required: true,
    },
    webp: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 48,
    },
    variant: {
      type: String,
      default: 'circle',
    },
  },
}
