//
//
//

import { mapGetters } from 'vuex'

export default {
  props: {
    countryCode: {
      type: String,
      default: '',
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      getCountryByCountryCode: 'locale/getCountryByCountryCode',
    }),
    cc() {
      let cc = this.countryCode
      if (!cc) {
        return 'none'
      }
      if (cc.length === 3) {
        this.getCountryByCountryCode(this.countryCode)
        const countryList = this.getCountryByCountryCode(this.countryCode)
        if (countryList && countryList.countryCode) {
          cc = countryList.countryCode ?? 'none'
        }
      }

      return cc.toLowerCase()
    },
  },
}
