//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getSearchResults from '~/gql/getSearchResults'

export default {
  data() {
    return {
      status: null,
      searchTerm: null,
      searchHistory: [],
      searchResults: {
        articles: [],
        hearingStories: [],
      },
      currentFilter: 'articles',
      inputFocus: false,
      lastSearchTerm: null,
      loading: false,
    }
  },

  computed: {
    filteredResults() {
      let results = [
        ...this.searchResults.articles,
        ...this.searchResults.hearingStories,
      ]

      if (this.currentFilter) {
        results = [...this.searchResults[this.currentFilter]]
      }

      return results
    },
  },

  mounted() {
    this.status = 'init'
    if (localStorage['hearpeers.searchHistory']) {
      this.searchHistory = JSON.parse(
        localStorage.getItem('hearpeers.searchHistory')
      )
    }
  },

  methods: {
    async onSubmit() {
      this.loading = true

      // sanitize search input
      const searchTerm = this.$sanitize(this.searchTerm, {
        allowedTags: [],
      })
      this.searchTerm = searchTerm

      // get search results
      if (this.searchTerm === '') return

      const { articles } = await this.$graphcms.request(getSearchResults, {
        search: searchTerm,
        lang: [this.$i18n.locale],
        assetLocales: [this.$i18n.locale, this.$i18n.defaultLocale],
      })
      this.searchResults.articles = articles.filter(
        (article) => article.isHearingStory === false
      )
      this.searchResults.hearingStories = articles.filter(
        (article) => article.isHearingStory === true
      )

      this.inputFocus = false

      this.lastSearchTerm = searchTerm

      // update status
      this.status = 'searched'

      // update searchHistory
      this.updateSearchHistory(searchTerm)

      this.loading = false
    },
    onInput(value) {
      this.searchTerm = value

      if (value === '') {
        this.clear()
      }
    },
    focusInput() {
      this.inputFocus = true
      this.status = 'init'
    },
    search(value) {
      this.searchTerm = value
      this.onSubmit()
    },
    clear() {
      for (const key of Object.keys(this.searchResults)) {
        this.searchResults[key] = []
      }
      this.status = 'init'
    },
    filter(value) {
      this.currentFilter = value
    },
    updateSearchHistory(value) {
      // get current searchHistory in localStorage
      let searchHistory =
        JSON.parse(localStorage.getItem('hearpeers.searchHistory')) ?? []

      // remove duplicated searchTerm in searchHistory
      const index = searchHistory.indexOf(value)
      if (index !== -1) {
        searchHistory.splice(index, 1)
      }

      // add searchTerm to start of searchHistory array
      searchHistory.unshift(value)

      // limit searchHistory to 5 items
      searchHistory = searchHistory.slice(0, 5)

      // update localStorage
      localStorage.setItem(
        'hearpeers.searchHistory',
        JSON.stringify(searchHistory)
      )
      this.searchHistory = searchHistory
    },
    getItemUrl(result) {
      let url = null

      if (
        result.topics &&
        result.topics.length > 0 &&
        result.isHearingStory === false
      ) {
        url = `/learn/${result.topics[0].slug}/${result.slug}`
      }

      if (result.isHearingStory === true) {
        url = `/hear/story/${result.slug}`
      }

      return url
    },
  },
}
