//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    stats: {
      type: Array,
      default() {},
    },
  },
}
