//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    headline: {
      type: String,
      default: null,
    },
    displayHeadline: {
      type: Boolean,
      deafult: true,
    },
    text: {
      type: Object,
      default() {},
    },
    link: {
      type: Object,
      default: null,
    },
    linkText: {
      type: String,
      default() {
        return this.$translation('button.learnMore')
      },
    },
  },
}
