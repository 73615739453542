//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    submitLabel: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisabled() {
      return this.loading || this.disabled
    },
    themeColors() {
      return this.$store.state.global.themes[this.theme]
    },
    style() {
      return {
        '--form-theme-color': this.themeColors?.color,
        '--form-theme-accent': this.themeColors?.accent,
        '--form-theme-font': this.themeColors?.font,
      }
    },
  },
  methods: {
    submit(e) {
      if (this.isDisabled) {
        return
      }
      e.preventDefault()
      this.$emit('submit', e)
    },
  },
}
