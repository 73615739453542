//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    items: {
      type: Array,
      default() {},
    },
    itemType: {
      type: String,
      default: '',
    },
    carouselType: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    current: {
      type: [Number, String],
      default: null,
    },
    setSelectedVideoUrl: Function,
    setShowModal: Function,
  },
  computed: {
    carouselItems() {
      return Array.isArray(this.items)
        ? this.items.filter((item) => item?.text !== null)
        : []
    },
  },

  methods: {
    handleScroll() {
      this.$emit('carousel:scroll')
    },
    resize() {
      this.$emit('carousel:resize')
    },
    setDetails(value) {
      this.$emit('carousel:details', value)
    },
  },
}
