//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiSearchHistory',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onSearch(searchTerm) {
      this.$emit('search', searchTerm)
    },
  },
}
