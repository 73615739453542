//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },

    webp: {
      type: String,
      default: null,
    },
  },
}
