//
//
//
//
//
//
//
//
//

export default {
  name: 'Spinner',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  computed: {
    classList() {
      const classList = []
      if (this.active) {
        classList.push('partial-spinner--active')
      }
      if (this.type) {
        classList.push(`partial-spinner--type-${this.type}`)
      }
      return classList.join(' ')
    },
  },
}
