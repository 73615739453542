//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: null,
    },
  },

  computed: {
    inputUrl() {
      const url = window.location.href
      return url
    },
    linkUrl() {
      return encodeURIComponent(this.url)
    },
  },
}
