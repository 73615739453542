//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      location: null,
    }
  },

  async fetch() {
    await this.$store.dispatch('locale/fetchWorldCountries')

    const localeChunks = this.$i18n.locale.split('_')

    if (localeChunks.length > 1) {
      const countryByCountryCode = this.$store.getters[
        'locale/getCountryByCountryCode'
      ](localeChunks[1])

      const localeInIsoAlpha3 = countryByCountryCode.isoAlpha3
      this.$store.commit('locale/setCurrentLocation', localeInIsoAlpha3)
    }
  },

  computed: {
    ...mapGetters({
      getCountryByCountryCode: 'locale/getCountryByCountryCode',
      currentCountry: 'locale/getCurrentLocation',
    }),

    dialog() {
      return this.$store.state.global.dialog === 'language-switch' ?? null
    },

    currentLocation() {
      const country = this.getCountryByCountryCode(this.currentCountry)
      return {
        name: country?.countryName ?? 'Worldwide',
        code: country?.isoAlpha3 ?? null,
      }
    },
  },
  watch: {
    currentLocation: {
      handler(newValue) {
        this.location = newValue.code
      },
      deep: true,
    },
  },

  methods: {
    openDialog() {
      this.$store.commit('global/setDialog', {
        dialog: 'language-switch',
        layer: false,
      })
    },
  },
}
