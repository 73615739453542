//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    control: {
      type: [Object, Array],
      default() {
        return {
          type: {
            type: String,
            default: '',
          },
          value: {
            type: String,
            default: '',
          },
        }
      },
    },
  },
  data() {
    return {
      listeners: {
        ...this.$listeners,
        input: (value) => this.onInput(value),
        change: (value) => this.onInput(value),
        invalid: (data) => this.onInvalid(data),
      },
      invalid: false,
      error: null,
    }
  },
  computed: {
    controls() {
      if (Array.isArray(this.control)) {
        return { controls: this.control }
      }
      return this.control
    },
    controlName() {
      return this.control.name ?? this.id
    },
    controlId() {
      return this.$slugify(
        'control-' + this.id + '-' + Math.floor(Math.random() * 100)
      )
    },
  },
  methods: {
    onInvalid(data) {
      this.invalid = data.invalid
      this.error = data.error
      this.$emit('invalid', data)
    },
    onInput(value) {
      this.$emit('input', value)
    },
  },
}
