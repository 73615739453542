//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
    size: {
      type: String,
      default: '',
    },
    badge: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    classes() {
      return {
        ['button--size-' + this.size]: this.size,
      }
    },
  },
}
