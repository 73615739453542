var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search"},[_c('UiForm',{staticClass:"search__form",attrs:{"id":"search"},on:{"submit":function($event){return _vm.onSubmit()}}},[_c('UiFormField',{attrs:{"id":"search","type":"search","control":{
        placeholder: _vm.$translation('form.search.placeholder'),
        clear: true,
        value: _vm.searchTerm,
        focus: _vm.inputFocus,
        loading: _vm.loading,
      }},on:{"input":function($event){return _vm.onInput($event)}}}),_vm._v(" "),_c('UiButton',{attrs:{"type":"submit","hidden":""}}),_vm._v(" "),_c('UiSearchTabs',{attrs:{"show":_vm.status !== 'init',"current":_vm.currentFilter,"items":['articles', 'hearingStories']},on:{"search:filter":function($event){return _vm.filter($event)}}})],1),_vm._v(" "),_c('div',{staticClass:"search__content"},[(_vm.status !== 'init' && _vm.filteredResults)?_c('div',{staticClass:"search__results"},[_c('h6',[_vm._v("\n        "+_vm._s(_vm.$translation('form.search.label.results', {
            length: _vm.filteredResults.length,
          }))+"\n      ")]),_vm._v(" "),(_vm.filteredResults.length > 0)?_vm._l((_vm.filteredResults),function(result,index){return _c('UiSearchResult',_vm._b({key:'search-result-' + index},'UiSearchResult',{
            title: result.title,
            url: _vm.getItemUrl(result),
            image: result.image,
            updatedAt: result.updatedAt,
            tags: result.tags,
          },false))}):_c('div',{staticClass:"search__empty"},[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$translation('form.search.text.no-results', {
              searchTerm: ("<strong>" + _vm.lastSearchTerm + "</strong>"),
            })
          )}}),_vm._v(" "),_c('UiButton',{attrs:{"variant":"link"},nativeOn:{"click":function($event){return _vm.focusInput()}}},[_vm._v(_vm._s(_vm.$translation('form.search.button.newSearch')))])],1)],2):_vm._e(),_vm._v(" "),(_vm.searchHistory && _vm.searchHistory.length > 0)?_c('UiSearchHistory',{attrs:{"items":_vm.searchHistory},on:{"search":function($event){return _vm.search($event)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }