//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
    tags: {
      type: Array,
      default: null,
    },
  },
  computed: {
    meta() {
      this.$dayjs(this.updatedAt)
        .locale(this.$i18n.localeProperties.iso)
        .format('MMMM')
      const time =
        '<time datetime="' +
        this.$datetime(this.updatedAt) +
        '">' +
        this.$dayjs(this.updatedAt)
          .locale(this.$i18n.localeProperties.iso)
          .format('ll') +
        '</time>'

      const tags = this.tags.length > 0 ? this.tags[0].title : null

      const template = tags
        ? `<p><strong>${tags}</strong> · ${time}</p>`
        : `<p>${time}</p>`

      return { template }
    },
  },
}
