//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: null,
    },
    boxShadow: {
      type: String,
      default: null,
    },
    showSteps: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alternative: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    progress() {
      return this.value / this.max
    },
    style() {
      return {
        '--progress-color': this.color,
        '--progress-background': this.background,
        '--progress-border-radius': this.borderRadius,
        '--progress-box-shadow': this.boxShadow,
        '--progress-height': this.height,
        '--progress-width': this.width,
      }
    },
  },
  mounted() {},
  methods: {
    onClickStep(e) {
      this.$emit('click:step', e.target.value)
    },
    truncateWords(text) {
      const words = text.split(' ')
      if (words.length > 6) {
        return words.slice(0, 6).join(' ') + '...'
      }
      return text
    },
  },
}
